import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
  HStack,
  Image,
  Center,
  Flex,
  Container,
  Heading,
  Text,
  Box,
  VStack,
  UnorderedList,
  ListItem,
  IconButton,
  Button,
  color,
  SimpleGrid,
  Divider,
  Spacer,
} from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { Formik, Form, Field } from 'formik';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { MdOutlineDeliveryDining } from 'react-icons/md';
import { IoEarthOutline } from 'react-icons/io5';
import { GrGroup } from 'react-icons/gr';
import { RiShipLine, RiFlightTakeoffFill } from 'react-icons/ri';
import { AiOutlineCar } from 'react-icons/ai';
import { Link } from 'gatsby';
import SwiperCore, { Navigation } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import {
  GFLButton,
  GFLTextFilledForm,
  TextUnderline,
} from '../components/atoms';
import GFLTextArea from '../components/atoms/GFLTextArea';
import { Footer, Header } from '../components/molecules';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
SwiperCore.use([Navigation]);
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaShuttleVan } from 'react-icons/fa';
import { BsPeopleFill } from 'react-icons/bs';
import { HiGlobe } from 'react-icons/hi';
import { BiPackage } from 'react-icons/bi';
import Loader from '../components/molecules/Loader';
import { testimonials } from '.';
import { useWindowSize } from '../hooks';

const Company = () => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const firstRef = useRef<HTMLDivElement>(null);
  const secondRef = useRef<HTMLDivElement>(null);
  const thirdRef = useRef<HTMLDivElement>(null);
  const targetRef = useRef<HTMLDivElement>(null);
  const [onFocus, setOnFocus] = useState(false);
  const { width, height } = useWindowSize();
  const handleScroll = useCallback(() => {
    if (targetRef.current) {
      const posOfTarget = targetRef!.current!.getBoundingClientRect().top;
      const posOfScreen = window.innerHeight / 1.3;

      if (posOfTarget < posOfScreen) {
        animationToggle(true);
      } else {
        animationToggle(false);
      }
    }
  }, []);
  useEffect(() => {
    AOS.init({
      once: true,
    });

    window.scrollTo(0, 0);
  }, []);
  const animationToggle = (status: boolean) => {
    if (firstRef.current && secondRef.current && thirdRef.current) {
      if (status) {
        // firstRef
        firstRef!.current!.style.left = '10%';
        firstRef!.current!.style.opacity = '1';

        //secondRef
        secondRef!.current!.style.top = '0';
        secondRef!.current!.style.opacity = '1';

        //thirdRef
        thirdRef!.current!.style.right = '10%';
        thirdRef!.current!.style.opacity = '1';
      } else {
        // firstRef
        firstRef!.current!.style.left = '-2%';
        firstRef!.current!.style.opacity = '.3';

        //secondRef
        secondRef!.current!.style.top = '15%';
        secondRef!.current!.style.opacity = '.3';

        //thirdRef
        thirdRef!.current!.style.right = '-2%';
        thirdRef!.current!.style.opacity = '.3';
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <>
      <Helmet>
        <title>About | GFL Group of Companies</title>

        <link
          href="https://#213A71cloudinary.com/shaun-storage/image/upload/v1646142917/GFL_GROUP_White_Design_xjwdcv.svg"
          rel="icon"
        ></link>
        <link
          href="https://#213A71cloudinary.com/shaun-storage/image/upload/v1646142917/GFL_GROUP_White_Design_xjwdcv.svg"
          rel="apple-touch-icon"
        ></link>

        <meta
          content="width=device-width, initial-scale=1.0"
          name="viewport"
        ></meta>
        <meta
          content="logistics, suppliers, shipping, warehousing, malaysian, malaysia, klang, freight, air freight, ship freight, rail freight, cargo, cargo express"
          name="keywords"
        />
        <meta
          name="description"
          content="Your solution to total cargo transport"
        />
        <meta name="robots" content="index, follow"></meta>
        <link rel="canonical" href="https://www.gflgroup.com.my" />
        <meta
          property="og:title"
          content="About - GFL | Your solution to total cargo transport"
        ></meta>
        <meta property="og:locale" content="en_US"></meta>
        <meta property="og:type" content="website"></meta>
        <meta
          property="og:description"
          content="Your solution to total cargo transport"
        ></meta>
        <meta property="og:url" content="https://www.gflgroup.com.my"></meta>
        <meta
          property="og:site_name"
          content="About - GFL | Your solution to total cargo transport"
        ></meta>
        <meta
          property="og:image"
          content="https://#213A71cloudinary.com/shaun-storage/image/upload/v1646142917/GFL_GROUP_White_Design_xjwdcv.svg"
        ></meta>
        <meta property="twitter:card" content="summary large image"></meta>
        <meta
          property="twitter:label1"
          content="Your solution to total cargo transport"
        ></meta>
      </Helmet>
      <Loader /> <Header />
      <VStack>
        <HStack
          w="100%"
          h={['20vh', '50vh', '70vh']}
          backgroundAttachment={'unset'}
          backgroundRepeat="no-repeat"
          backgroundImage={'/about/aboutus-slider.jpg'}
          position="sticky"
          backgroundSize={['145%', 'cover', 'cover']}
          backgroundPosition="0% center"
          justifyContent="center"
          pl={['1.5rem', '3rem', '6rem']}
        >
          <VStack w="100%" alignItems={'flex-start'}>
            <Heading
              fontSize={['1.25rem', '2.5rem', '3rem']}
              color="rgb(255, 255, 255)"
              className="title-animation"
            >
              About {width > 768 ? <br></br> : <></>} GFL Group
            </Heading>
            <TextUnderline color="#ffffff" opacity=".8" />
          </VStack>
        </HStack>
      </VStack>
      <Flex
        flexDir={['column', 'column', 'row']}
        py={['2rem', '3rem', '6.5rem']}
        px={['1em', '4em', '8em']}
        gap="1.8em"
        id="who-are-we"
      >
        <VStack w={['100%', '100%', '50%']} alignItems={'flex-start'}>
          <Heading fontSize={'3rem'} color="#213A71" w="100%">
            WHO ARE WE
          </Heading>
          <Image
            src={'/logo/GFL_GROUP_LIGHT.svg'}
            alt="GFL Logo"
            width={'45%'}
            height={'100%'}
            onClick={() => {
              if (typeof window !== 'undefined') {
                window.location.href = '/';
              }
            }}
            cursor="pointer"
          />
          <Text fontWeight={'bold'} fontSize={'xl'} color="black">
            Your Solution to Total Cargo Transportation
          </Text>
        </VStack>

        <Text w={['100%', '100%', '50%']} px={['0px', '0px', '40px']}>
          GFL Group is a trusted Malaysian based (Port Klang) logistics service
          provider established since year 1991. GFL Group offers complete and
          comprehensive logistics services across all spectrums in the supply
          chain. These services include freighting, customs forwarding, haulage
          & transportation, warehousing & distribution, and fulfilment services.{' '}
          <br />
          <br /> Over the years, GFL Group has grown to become a significant
          player in the freight forwarding and warehousing business in the Intra
          Asia region. With the home court advantage & logistics expertise, we
          help our clients to grow sustainably by ensuring their logistics needs
          are well taken of.
        </Text>
      </Flex>
      <Flex
        w="100%"
        flexDir={['column-reverse', 'column-reverse', 'row']}
        id="about"
        px={['1em', '4em', '8em']}
      >
        <VStack
          w={['100%', '100%', '50%']}
          px={['0px', '0px', '40px']}
          py="40px"
        >
          <Text w="100%">
            <Flex alignItems={'center'}>
              <TextUnderline
                color="#213A71"
                opacity=".8"
                w="4%"
                mr="10px"
                style={{
                  marginBottom: '-2px',
                }}
              />

              <Heading fontSize={['1.5rem', '1.8rem', '2.4rem']} mb="1">
                About
              </Heading>
            </Flex>
            <br></br>
            The Group consists of 4 different entities namely ISC Sdn Bhd, Gulf
            Freight Line Sdn Bhd, GFL Warehousing & Distribution (M) Sdn Bhd and
            OPP Bulk Services Sdn Bhd. Spearheaded by the same management, these
            entities worked seamlessly and synergistically under the name of GFL
            Group for the past decades. Back then, the Group started off as an
            exclusive bulk liquid transportation agent.
            <br />
            <br />
            The idea of providing a one-stop logistics solutions motivated us to
            not only limited to bulk liquid sector but a versatile player in
            general, hence becoming a total logistics service provider. Over the
            years, GFL Group had become a significant total logistics player in
            the Intra-Asia region, credits to 3 important core pillars: (1)
            Dedicated and Accountable Staff Members, (2) Credible and
            Trustworthy Business Partners and (3) Ample Operation Facilities.
          </Text>
        </VStack>
        <VStack
          w={['100%', '100%', '50%']}
          overflow={'hidden'}
          cursor="pointer"
        >
          <Center
            backgroundImage={'/about/baoutus-firstbanner.jpg'}
            backgroundAttachment="scroll"
            backgroundPosition="center center"
            backgroundRepeat={'no-repeat'}
            backgroundSize="cover"
            height={['35vh', '45vh', '100%']}
            w="100%"
            transition="transform .6s"
            _hover={{
              transform: 'scale(1.05)',
            }}
          ></Center>
        </VStack>
      </Flex>
      <Flex
        w="100%"
        flexDir={['column', 'column', 'row']}
        px={['1em', '4em', '8em']}
      >
        <VStack
          w={['100%', '100%', '50%']}
          overflow={'hidden'}
          cursor="pointer"
        >
          <Center
            backgroundImage={'/about/792-x-392-2.jpg'}
            backgroundAttachment="scroll"
            backgroundPosition="center center"
            backgroundRepeat={'no-repeat'}
            backgroundSize="cover"
            height={['35vh', '45vh', '100%']}
            w="100%"
            transition="transform .6s"
            _hover={{
              transform: 'scale(1.05)',
            }}
          ></Center>
        </VStack>
        <VStack
          w={['100%', '100%', '50%']}
          px={['0px', '0px', '40px']}
          py="40px"
          alignItems={'flex-start'}
        >
          <Text w="100%">
            Our team believe enthusiasm is like a magnet attracting people to
            one’s cause. Staff members are well cultivated on this belief,
            advocating positivity and responsibility when comes to servicing our
            clients. We have also built strong relationship with business
            partners across the country and overseas.
            <br />
            <br />
            Our business partners are as committed in providing high quality
            services and has developed good chemistry with us over these years.
            We are proud to be associated with these stakeholders. At GFL Group,
            we understand how important – and how necessary reliable,
            accountable fulfilment and distribution services are to one
            business. Therefore, we are here ready to serve our client by
            committing our services in a reliable, professional, and effective
            manner, ensuring your logistics needs are well taken of.
            <br />
          </Text>

          <Spacer />
          <Spacer />
          <GFLButton
            onClick={() => {
              window.location.href = '/services';
            }}
            color="white"
            fontWeight="bold"
            px="40px"
            py="10px"
            mt="5"
            minH="50px"
            style={{
              marginTop: '20px !important',
            }}
          >
            Know Our Services
          </GFLButton>
        </VStack>
      </Flex>
      <Center px={['1em', '2em', '2em']} mt="5rem">
        <Swiper
          autoplay={true}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={(swiper) => {
            // @ts-ignore
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            // @ts-ignore
            swiper.params.navigation.nextEl = navigationNextRef.current;
          }}
          slidesPerView={width < 768 ? 1 : width < 1000 ? 2 : 3}
          className="mySwiper"
          style={{
            width: '100%',
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
            paddingBottom: '30px',
          }}
        >
          <SwiperSlide
            style={{
              paddingTop: '20px',
              paddingBottom: '20px',
              paddingRight: width < 768 ? '0px' : '15px',
            }}
          >
            <VStack overflow={'hidden'} cursor="pointer">
              <Image
                src={'/about/8slider/320x246-1.jpg'}
                alt=""
                w="100%"
                transition="transform .6s"
                _hover={{
                  transform: 'scale(1.2)',
                }}
              />
            </VStack>
          </SwiperSlide>
          <SwiperSlide
            style={{
              paddingTop: '20px',
              paddingBottom: '20px',
              paddingRight: width < 768 ? '0px' : '15px',
            }}
          >
            <VStack overflow={'hidden'} cursor="pointer">
              <Image
                src={'/about/8slider/320x246-2.jpg'}
                w="100%"
                transition="transform .6s"
                _hover={{
                  transform: 'scale(1.2)',
                }}
              />
            </VStack>
          </SwiperSlide>
          <SwiperSlide
            style={{
              paddingTop: '20px',
              paddingBottom: '20px',
              paddingRight: width < 768 ? '0px' : '15px',
            }}
          >
            <VStack overflow={'hidden'} cursor="pointer">
              <Image
                src={'/about/8slider/320x246-3.jpg'}
                w="100%"
                transition="transform .6s"
                _hover={{
                  transform: 'scale(1.2)',
                }}
              />
            </VStack>
          </SwiperSlide>
          <SwiperSlide
            style={{
              paddingTop: '20px',
              paddingBottom: '20px',
              paddingRight: width < 768 ? '0px' : '15px',
            }}
          >
            <VStack overflow={'hidden'} cursor="pointer">
              <Image
                src={'/about/8slider/320x246-4.jpg'}
                w="100%"
                transition="transform .6s"
                _hover={{
                  transform: 'scale(1.2)',
                }}
              />
            </VStack>
          </SwiperSlide>
          <SwiperSlide
            style={{
              paddingTop: '20px',
              paddingBottom: '20px',
              paddingRight: width < 768 ? '0px' : '15px',
            }}
          >
            <VStack overflow={'hidden'} cursor="pointer">
              <Image
                src={'/about/8slider/320x246-5.jpg'}
                alt=""
                w="100%"
                transition="transform .6s"
                _hover={{
                  transform: 'scale(1.2)',
                }}
              />
            </VStack>
          </SwiperSlide>
          <SwiperSlide
            style={{
              paddingTop: '20px',
              paddingBottom: '20px',
              paddingRight: width < 768 ? '0px' : '15px',
            }}
          >
            <VStack overflow={'hidden'} cursor="pointer">
              <Image
                src={'/about/8slider/320x246-6.jpg'}
                w="100%"
                transition="transform .6s"
                _hover={{
                  transform: 'scale(1.2)',
                }}
              />
            </VStack>
          </SwiperSlide>
          <SwiperSlide
            style={{
              paddingTop: '20px',
              paddingBottom: '20px',
              paddingRight: width < 768 ? '0px' : '15px',
            }}
          >
            <VStack overflow={'hidden'} cursor="pointer">
              <Image
                src={'/about/8slider/320x246-7.jpg'}
                w="100%"
                transition="transform .6s"
                _hover={{
                  transform: 'scale(1.2)',
                }}
              />
            </VStack>
          </SwiperSlide>
          <SwiperSlide
            style={{
              paddingTop: '20px',
              paddingBottom: '20px',
              paddingRight: width < 768 ? '0px' : '15px',
            }}
          >
            <VStack overflow={'hidden'} cursor="pointer">
              <Image
                src={'/about/8slider/320x246-8.jpg'}
                w="100%"
                transition="transform .6s"
                _hover={{
                  transform: 'scale(1.2)',
                }}
              />
            </VStack>
          </SwiperSlide>

          <IconButton
            ref={navigationPrevRef}
            aria-label="Prev Slide"
            icon={<ChevronLeftIcon fill="white" stroke="white" />}
            style={{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              left: width < 768 ? '12px' : '0px',
              zIndex: 1001,
              backgroundColor: '#000000',

              boxShadow: '0px 0px 10px #e9e9e9',
            }}
          />
          <IconButton
            ref={navigationNextRef}
            aria-label="Next Slide"
            icon={<ChevronRightIcon fill="white" stroke="white" />}
            style={{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              right: '12px',
              zIndex: 1001,
              backgroundColor: '#000000',
              boxShadow: '0px 0px 10px #e9e9e9',
            }}
          />
        </Swiper>
      </Center>
      <Center
        py="5em"
        my="2em"
        position="relative"
        overflow={'hidden'}
        backgroundColor={'#213A71'}
        id="mission-and-vision"
      >
        <SimpleGrid columns={[1, 1, 2]} color="white">
          <VStack
            w={'100%'}
            px="40px"
            alignItems={'center'}
            mb={['40px', '40px', '0px']}
          >
            <Image src={'/custom-icons/earth-white.svg'} />
            <br />
            <Heading color={'white'}>Vision</Heading>
            <br />
            <Text w={['100%', '100%', '50%']} textAlign={'center'}>
              To become global business owners first-choice logistics partner in
              providing total cargo transportation solutions.
            </Text>
          </VStack>
          <VStack w={'100%'} px="40px" alignItems={'center'}>
            <Image src={'/custom-icons/mission.svg'} />
            <br />
            <Heading color={'white'}>Mission</Heading>
            <br />
            <Text w={['100%', '100%', '50%']} textAlign={'center'}>
              To promote professionalism and proper human interactions among the
              workforce, encouraging a healthy working environment which is
              ready to strive for quality & excellence.
            </Text>
          </VStack>
        </SimpleGrid>
      </Center>
      <Center pt={['1.5em', '2.5em', '3.5em']} id="core-values">
        <Container maxW={'container.xl'} textAlign={'center'}>
          <SimpleGrid columns={[1, 1, 2]}>
            <VStack w="100%" alignItems={['center', 'center', 'flex-start']}>
              <Heading fontSize={['1.5rem', '1.8rem', '2.4rem']} mb="1">
                Core Values
              </Heading>
              <TextUnderline marginTop={'2px !important'} />
              <Flex
                flexDir={'column'}
                alignItems={'flex-start'}
                justifyContent={'flex-start'}
                textAlign="start"
                mb="1rem !important"
                w={'100%'}
              >
                <Text textAlign={'start'}>
                  <span style={{ fontWeight: 'bold', color: '#f93615' }}>
                    Ikhlas
                  </span>{' '}
                  dengan diri sendiri; Sincere with your own;
                </Text>
                <Text textAlign={'start'}>
                  <span style={{ fontWeight: 'bold', color: '#f93615' }}>
                    Ikhlas
                  </span>{' '}
                  dengan kawan sekerja; Sincere with your colleagues;
                </Text>
                <Text textAlign={'start'}>
                  <span style={{ fontWeight: 'bold', color: '#f93615' }}>
                    Ikhlas
                  </span>{' '}
                  dengan pemegang kepentingan; Sincere with your stakeholders;
                </Text>
              </Flex>

              <Flex
                flexDir={'column'}
                alignItems={'flex-start'}
                justifyContent={'flex-start'}
                textAlign="start"
                mb="1rem !important"
                w={'100%'}
              >
                <Text textAlign={'start'}>
                  <span style={{ fontWeight: 'bold', color: '#f93615' }}>
                    {' '}
                    Setia{' '}
                  </span>{' '}
                  kepada negara; Loyal to our country
                </Text>
                <Text textAlign={'start'}>
                  <span style={{ fontWeight: 'bold', color: '#f93615' }}>
                    {' '}
                    Setia{' '}
                  </span>{' '}
                  kepada keluarga; Loyal to our family
                </Text>
                <Text textAlign={'start'}>
                  <span style={{ fontWeight: 'bold', color: '#f93615' }}>
                    {' '}
                    Setia{' '}
                  </span>{' '}
                  kepada majikan; Loyal to our employer
                </Text>
              </Flex>

              <Flex
                flexDir={'column'}
                alignItems={'flex-start'}
                justifyContent={'flex-start'}
                textAlign="start"
                mb="1rem !important"
                w={'100%'}
              >
                <Text textAlign={'start'}>
                  <span style={{ fontWeight: 'bold', color: '#f93615' }}>
                    Cekap{' '}
                  </span>{' '}
                  dalam perkhidmatan pelanggan; Efficient in handling customer
                  services
                </Text>
                <Text textAlign={'start'}>
                  <span style={{ fontWeight: 'bold', color: '#f93615' }}>
                    Cekap{' '}
                  </span>{' '}
                  dalam akal fikiran; Efficient in mind and thoughts
                </Text>
                <Text textAlign={'start'}>
                  <span style={{ fontWeight: 'bold', color: '#f93615' }}>
                    Cekap{' '}
                  </span>{' '}
                  dalam pelaksanaan tugas; Efficient in task implementation
                </Text>
              </Flex>
            </VStack>

            <Center
              backgroundImage={'/about/624-x-292.jpg'}
              backgroundAttachment="scroll"
              backgroundPosition="center center"
              backgroundRepeat={'no-repeat'}
              backgroundSize="cover"
              height="100%"
              w="100%"
              transition="transform .6s"
              _hover={{
                transform: 'scale(1.2)',
              }}
            ></Center>
          </SimpleGrid>
        </Container>
      </Center>
      <Center
        backgroundSize="container"
        backgroundPosition={'center center'}
        backgroundRepeat="no-repeat"
        backgroundImage="https://morz.vamtam.com/wp-content/uploads/2018/01/map-dots-trans.svg"
        py={['3.5em', '4.5em', '7.5em']}
      >
        <Container maxW="container.xl">
          <HStack w="100%" justifyContent={'center'} mb={['4em', '4em', '7em']}>
            <Text fontSize={['1.5rem', '1.8rem', '2.4rem']} color="black">
              <span style={{ fontWeight: '700', color: '#213A71' }}>
                Performance
              </span>{' '}
              Dashboard
            </Text>
          </HStack>
          <Flex
            justifyContent={'center'}
            alignItems="center"
            flexDir={['column', 'column', 'row']}
            gap="2em"
            w="100%"
          >
            <VStack alignItems={['center', 'center', 'flex-start']}>
              <BiPackage style={{ fontSize: '3.3rem', color: '#213A71' }} />
              <Heading color="#213A71" fontSize="3rem">
                <CountUp end={100} start={onFocus ? 0 : -1}>
                  {({ countUpRef }) => (
                    <VisibilitySensor
                      onChange={(isVisible) => {
                        isVisible && setOnFocus(true);
                      }}
                      delayedCall
                    >
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
                K+
              </Heading>
              <Text fontWeight="600" textAlign={['center', 'center', 'start']}>
                {' '}
                No. of TEUs
              </Text>
              <Text textAlign={['center', 'center', 'start']}>
                We strongly support best practice sharing across our operations
                around the world and across various industrial sectors.
              </Text>
            </VStack>
            <VStack alignItems={['center', 'center', 'flex-start']}>
              <HiGlobe style={{ fontSize: '3.3rem', color: '#213A71' }} />
              <Heading color="#213A71" fontSize="3rem">
                <CountUp end={50} start={onFocus ? 0 : -1}>
                  {({ countUpRef }) => (
                    <VisibilitySensor
                      onChange={(isVisible) => {
                        isVisible && setOnFocus(true);
                      }}
                      delayedCall
                    >
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
              </Heading>
              <Text fontWeight="600" textAlign={['center', 'center', 'start']}>
                Agents/Branches
              </Text>
              <Text textAlign={['center', 'center', 'start']}>
                We design and advise industry logistics solutions to help client
                in their supply chain management
              </Text>
            </VStack>
            <VStack alignItems={['center', 'center', 'flex-start']}>
              <BsPeopleFill style={{ fontSize: '3.3rem', color: '#213A71' }} />
              <Heading color="#213A71" fontSize="3.2rem">
                <img
                  style={{ height: '64px', width: '64px' }}
                  src={'/home/infinity.svg'}
                />
              </Heading>
              <Text fontWeight="600" textAlign={['center', 'center', 'start']}>
                Satisfied Clients
              </Text>
              <Text textAlign={['center', 'center', 'start']}>
                GFL applies its operational expertise to provide best-in-class
                services across its integrated worldwide network.
              </Text>
            </VStack>
          </Flex>
        </Container>
      </Center>
      <VStack backgroundColor={'#fAFAFA'} p="5em 0 " id="dato-message">
        <Center>
          <Container maxW="container.xl">
            <VStack spacing="5em" w="100%">
              <Flex flexDir={['column', 'column', 'row']}>
                <VStack
                  w={['100%', '100%', '65%']}
                  background={'#FFF'}
                  justifyContent="center"
                  alignItems={'flex-start'}
                  px="2em"
                  py="2em"
                >
                  <Heading fontSize={['1.5rem', '1.8rem', '2.4rem']}>
                    Message from Group Managing Director, Dato’ Roger Eng
                  </Heading>
                  <TextUnderline />
                  <Text pt="12px">
                    Logistics have always been an indispensable part of our
                    lives. We are proud to be in the logistics industry, acting
                    as the intermediary to connect buyers and sellers through
                    providing logistics arrangements. <br />
                    <br /> Our company vision has always been the same, we want
                    to become the first-choice logistics partner with
                    professional reputation and accountability for global
                    business owners. It is with this great vision in mind that
                    every member of the GFL team dedicates and put in their best
                    effort in the daily work.
                    <br />
                    <br />
                    Not to mention the strong and impactful company cultures and
                    core values we have built up through our journey.
                  </Text>
                </VStack>
                <VStack
                  w="100%"
                  overflow={'hidden'}
                  cursor="pointer"
                  backgroundColor={'#213A71'}
                >
                  <Center
                    backgroundImage={'/about/740x620.jpg'}
                    backgroundAttachment="scroll"
                    backgroundPosition="center center"
                    backgroundRepeat={'no-repeat'}
                    backgroundSize={['contain', 'contain', 'cover']}
                    height={['35vh', '45vh', '70vh']}
                    w="100%"
                    transition="transform .6s"
                    _hover={{
                      transform: 'scale(1.05)',
                    }}
                  ></Center>
                </VStack>
              </Flex>
              <Flex flexDir={['column-reverse', 'column-reverse', 'row']}>
                <VStack
                  w="100%"
                  overflow={'hidden'}
                  cursor="pointer"
                  backgroundColor={'#213A71'}
                >
                  <Center
                    backgroundImage={'/about/790x460.jpg'}
                    backgroundAttachment="scroll"
                    backgroundPosition="center center"
                    backgroundRepeat={'no-repeat'}
                    backgroundSize={['contain', 'contain', 'cover']}
                    height={['35vh', '45vh', '60vh']}
                    w="100%"
                    transition="transform .6s"
                    _hover={{
                      transform: 'scale(1.2)',
                    }}
                  ></Center>
                </VStack>
                <VStack
                  w={['100%', '100%', '65%']}
                  background={'#FFF'}
                  justifyContent="center"
                  alignItems={'flex-start'}
                  px="2em"
                  py="2em"
                >
                  <Text pt="12px">
                    It helped us to create the same understanding & language
                    among the team, developing synergies and achieving our
                    target. As part of the social responsibility, we create
                    cultures in which talented individuals can display their
                    capabilities to the fullest, allowing them to achieve
                    personal as well as career growth.
                    <br />
                    <br /> We continue to seek qualitative and sustainable
                    growth through greater teamplays and development of
                    innovative business models by bringing values to our
                    customers through providing integrated one stop logistics
                    solutions.
                    <br />
                    <br /> I believe in our good future by delivering our
                    commitments and striving for higher standards of excellence,
                    we can bring GFL Group to greater height and achievements.
                    Best regards.
                  </Text>
                </VStack>
              </Flex>
            </VStack>
          </Container>
        </Center>
      </VStack>
      <Center py="1.5em" pb="50px" backgroundColor="#fafafa">
        <Container maxW="container.xl" px={[0, 0, '14px']} position="relative">
          <VStack gap="1.2em" paddingX={['20px', '20px', '0px']}>
            <VStack>
              <Heading fontSize={['1.5rem', '1.8rem', '1.8rem']}>
                Testimonials
              </Heading>
              <Text color={'#000'}>What People and Clients Think About Us</Text>
              <br />
              <TextUnderline w="40%" />
            </VStack>

            <IconButton
              ref={navigationNextRef}
              aria-label="Next Slide"
              icon={<ChevronRightIcon style={{ color: 'white' }} />}
              style={{
                position: 'absolute',
                top: '50%',
                transform:
                  width < 768
                    ? 'translateX(-40%) translateY(20%)'
                    : 'translateX(-40%) translateY(-30%)',
                right: '0px',
                zIndex: 1001,
                backgroundColor: 'red',
                borderRadius: '50%',
              }}
            />

            <Swiper
              autoplay={true}
              loop={true}
              navigation={{
                nextEl: navigationNextRef.current,
              }}
              pagination={{
                clickable: true,
              }}
              onBeforeInit={(swiper) => {
                // @ts-ignore
                swiper.params.navigation.nextEl = navigationNextRef.current;
              }}
              slidesPerView={width < 768 ? 1 : width < 1000 ? 2 : 2}
              className="mySwiper"
              style={{
                width: '100%',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
                paddingBottom: '30px',
              }}
            >
              {testimonials.map((test, index) => (
                <SwiperSlide
                  style={{
                    paddingRight: width < 768 ? '0px' : '15px',
                    position: 'relative',
                  }}
                  key={index}
                >
                  <Flex
                    flexDir={['column', 'column', 'row']}
                    overflow={'hidden'}
                    cursor="pointer"
                    gap={'0px'}
                    backgroundColor="rgb(33, 58, 113)"
                    height={width < 1000 ? '100%' : '275px'}
                    paddingX={['20px', '20px', '0px', '0px']}
                    style={{
                      backgroundImage: 'url(/home/testimonial/test-shape.svg)',
                      backgroundAttachment: 'fixed',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                    }}
                  >
                    <Heading
                      d={['none', 'none', 'none', 'flex']}
                      position={'absolute'}
                      bottom="20px"
                      right="40px"
                      color={'#FFFFFF'}
                      fontSize="18px"
                    >
                      {test?.position}
                    </Heading>
                    <VStack
                      w={['100%', '100%', '40%', '25%']}
                      height={'100%'}
                      padding={'20px'}
                      alignItems={'center'}
                      justifyContent={'flex-start'}
                    >
                      <Image
                        src={test!.imgUrl}
                        alt=""
                        w="80%"
                        transition="transform .6s"
                        _hover={{
                          transform: 'scale(1.05)',
                        }}
                      />
                    </VStack>

                    <VStack
                      w={['100%', '75%', '75%', '75%']}
                      justifyContent={'flex-start'}
                      alignItems={'flex-start'}
                      pr="1.5em"
                      py="1.5em"
                      height={'100%'}
                      color={'white'}
                      position="relative"
                      style={{
                        marginTop: '0px',
                      }}
                    >
                      <Heading
                        textAlign={'start'}
                        color="white"
                        fontSize={'24px'}
                        marginBottom={'8px'}
                      >
                        {test!.name}
                        <br></br>
                      </Heading>

                      <Heading
                        d={['flex', 'flex', 'flex', 'none']}
                        bottom="20px"
                        right="40px"
                        color={'#FFFFFF'}
                        fontSize="18px"
                      >
                        {test?.position}
                      </Heading>

                      <Text textAlign={'start'} color="#FFFFFF">
                        {test!.content}
                      </Text>
                    </VStack>
                  </Flex>
                </SwiperSlide>
              ))}
            </Swiper>
          </VStack>
        </Container>
      </Center>
      <Footer />
    </>
  );
};

export default Company;
